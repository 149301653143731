.card-container {
  display: flex;
  max-width: 1100px;
  justify-content: space-between;
  margin: 0 auto;
  padding: clamp(50px, 5%, 70px) clamp(20px, 5%, 100px);
  gap: 5%;
  row-gap: 10vh;
  .card {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 auto;
  }
  .history {
    width: 50%;
  }
  .card-title {
    font-weight: 400;
    font-size: 40px;
  }
  .link {
    width: 50%;
    display: block;
    margin-left: auto;
    button {
      width: 100%;
    }
  }
  .spin {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.visa {
  background: radial-gradient(70% 150% at 30% 50%, #d0a549, #986c0e);
  aspect-ratio: 8/5;
  padding: 20px;
  border-radius: 20px;
  .visa-title {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  .visa-type {
    margin: 11px 0 26px;
    display: inline-block;
    background-color: #ad8124;
    padding: 3px 15px;
    border-radius: 30px;
  }
  .visa-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
  }
  .visa-cost {
    h1 {
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
  .visa-logo {
    img {
      margin: 0 auto 20px;
      width: 56px;
      display: block;
    }
    h1 {
      font-weight: 900;
      font-size: 50px;
      font-family: "Inter";
      font-style: italic;
    }
  }
}

.history {
  height: 100%;
  .card-title {
    margin-bottom: 30px;
  }
  .history-lists {
    max-height: 320px;
    overflow-y: auto;
    padding: 2px;
  }
  .history-list {
    --color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 15px;
    gap: 10%;
    border: 2px solid var(--color);
    h3 {
      color: var(--color);
    }
    &.active p {
      margin-right: auto;
    }
    h2,
    h3 {
      font-weight: 500;
    }
    p {
      color: #939aab;
      font-weight: 500;
    }
    &.silver {
      --color: #c0c0c0;
    }
    &.bronze {
      --color: #e59f1c;
    }
    &.gold {
      --color: #dbb860;
    }
  }
}

@media (max-width: 1000px) {
  .card-container {
    flex-direction: column;
    width: clamp(220px, 100%, 500px);
    .spin{
      width: 100%;
    }
    .card,
    .history {
      width: 100%;
    }
  }
  .history {
    .history-lists {
      max-height:unset;
      height: unset;
      overflow-y: unset;
      padding: 0;
    }
  }
}

@media (max-width: 500px) {
  .card-container .visa {
    &-title {
      font-size: 3.5vw;
    }
    &-type {
      font-size: 3vw;
      padding: 2px 10px;
    }
    &-logo {
      h1 {
        font-size: 10vw;
      }
      img {
        width: 15vw;
      }
    }
    &-cost {
      h1 {
        font-size: 7vw;
      }
      p {
        font-size: 3vw;
      }
    }
  }
  .card-container {
    .link {
      width: 100%;
    }
  }
  .history {
    &-list {
      padding: 4vw !important;
      border-radius: 3vw !important;
      h2 {
        font-size: 5vw;
      }
      h3 {
        font-size: 4vw;
      }
      p {
        font-size: 3vw;
      }
    }
  }
}
