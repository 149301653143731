.navbar {
  background-color: var(--light-primary-color);
  height: 50px;
  box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.5);
  padding: 0 5%;
  z-index: 100;
  
}
.back {
  color: #c1c4d1;
  display: flex;
  gap: 3vw;
  font-size: 20px;
  height: 100%;
  align-items: center;
  svg {
    font-size: 25px;
  }
  &:hover{
    color: var(--light-font-color);
  }
}
.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.profile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  height: 100%;

  .links {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 4vw;
  }
  a {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .menuEl {
    display: none;
  }

  @media (max-width: 700px) {
    .back {
      font-size: 15px;
      svg {
        font-size: 20px;
      }
    }

    .menuEl {
      display: block;
    }
    .closeBtn {
      margin-right: 5vw;
    }
    .menuBg {
      position: fixed;
      inset: 0;
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.6);
      transition: 0.1s;
    }
    .links {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      flex-direction: column;
      background-color: var(--light-primary-color);
      z-index: 100;
      width: 90%;
      max-width: 300px;
      align-items: flex-end;
      gap: 10px;
      padding: 10px 0;
      transition: 0.4s;

      a {
        padding: 10px;
        display: block;
        text-align: center;
        width: 100%;
        height: unset;
      }
      button {
        display: none;
      }
    }
    &.closed {
      // .links,
      // .menuBg {
      //   display: none;
      // }
      .links {
        translate: 100%;
      }
      .menuBg {
        // translate: 100%;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  p {
    font-size: 20px;
    font-weight: 500;
  }
  button {
    .icon {
      display: none;
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 16px;
    }
    button {
      .icon {
        display: block;
      }
      .word {
        display: none;
      }
    }
  }
}
