.cards {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1300px;
  gap: 1%;
}
.confirm {
  & > div:nth-child(2) {
    background-color: var(--light-primary-color);
    border: 1px solid var(--gold-color);
  }
  div,span{
    color: var(--white-color) !important;
  }
  svg{
    color: var(--gold-color);
  }
  button:last-child span {
    color: var(--light-primary-color) !important;
  }
  button:first-child,button:first-child:hover{
    background: transparent !important;
    border-color: var(--gold-color) !important;
    span{
      color: var(--gold-color) !important;

    }
  }
}           

.card {
  background-color: var(--light-primary-color);
  border: 1px solid var(--color);
  width: 33%;
  padding: 40px 25px;
  border-radius: 20px;
  &:nth-child(2) {
    margin-bottom: 50px;
  }
  &:first-child,
  &:last-child {
    margin-top: 50px;
  }
  h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: var(--color);
  }
  button:hover {
    background: var(--color) !important;
    opacity: 0.9;
  }
 
  .advantages {
    margin: 30px 0; svg {
    color: var(--color);
    font-size: 15px;
    min-width: 15px;
  }
    p {
      margin-bottom: 10px;
      display: flex;
      gap: 5px;
      font-weight: 300;
      font-size: 14px;
      color: var(--light-font-color);
      letter-spacing: 0.7px;
    }
  }
  .description {
    margin-bottom: 20px;
    color: var(--color);
    text-align: center;
  }
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
@media (max-width: 900px) {
  .cards {
    // flex-direction: column;
    flex-wrap: wrap;
    // max-width: 400px;
    margin: 0 auto;
    row-gap: 30px;
    justify-content: center;
  }
  .card {
    width: 48%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
@media (max-width: 750px) {
  .cards {
    // flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    //   row-gap: 30px;
    //   justify-content: center;
  }
  .card {
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
