.content {
  padding: 10vh 5vw;
  height: 100vh;
  overflow-y: scroll;
  & > h1 {
    font-weight: 400;
    color: var(--light-font-color);
    font-size: clamp(24px, 5vw, 48px);
  }
}

.container {
  display: flex;
  gap: clamp(15px, 2vw, 50px);
  // align-items: flex-start;
  padding-top: 40px;
}

.main-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trailer-container {
  width: 35%;
  position: relative;
  padding-bottom: 30px;
  .trailer {
    position: sticky;
    top: 0px;
    background-color: var(--light-primary-color);
    padding: 20px;
    border-radius: 10px;
    video {
      object-fit: cover;
      width: 100%;
      aspect-ratio: 1920/1000;
      border-radius: 5px;
    }
    p {
      font-size: 16px;
      margin: 20px 0;
    }
  }
}
.sections {
  width: 65%;
}

.section {
  background-color: var(--light-primary-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 15px;
  .title,
  .video,
  .description {
    padding: 20px;
  }
  .title {
    font-size: 24px;
    color: var(--gold-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .description {
    color: var(--light-font-color);
    font-size: 15px;
    line-height: 130%;
  }
  .video {
    display: flex;
    align-items: center;
    gap: 20px;
    .lock {
      padding: 5px;
      background-color: var(--dark-primary-color);
      aspect-ratio: 1/1;
      width: 40px;
      border-radius: 5px;
    }

    h2 {
      font-size: 18px;
      font-family: "Montserrat";
      font-weight: 400;
      color: var(--light-font-color);
    }
  }
}

@media (max-width: 1000px) {
  .sections {
    width: 55%;
  }
  .trailer-container {
    width: 45%;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
  }
  .sections,
  .trailer-container {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .section {
    border-radius: 5px;
    .title,
    .video,
    .description {
      padding: clamp(10px, 5vw, 20px);
    }
    .title {
      font-size: clamp(15px, 8vw, 24px);
    }
    .video h2 {
      font-size: clamp(10px, 5vw, 18px);
    }
    .description {
      padding-block: 5px;
      font-size: clamp(5px, 3vw, 15px);
    }
    .video {
      gap: 10px;
    }
  }
  .trailer-container .trailer{
    border-radius: 5px;
    padding: clamp(10px, 5vw, 20px);

  }
}
