.container {
  padding: 20vh 5vw 50px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  height: 100%;
  overflow-y: auto;

  .content {
    margin: 0 auto;
    width: clamp(250px, 100%, 700px);
    display: flex;
    min-height: 300px;
    justify-content: space-between;
    gap: 30px;
  }
  .form {
    // V1
    // width: 100%;

    //V2
    width: 60%;
    margin: 0 auto;

    .links {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
    }
    &-content {
      padding: 100px 7% 50px;
      height: 100%;
    }
  }
  .info {
    width: 40%;
    &-content {
      height: 100%;
      padding: 50px 7%;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
    &-section {
      display: flex;
      flex-direction: column;
      gap: 15px;
      color: white;
    }
    h1 {
      color: var(--gold-color);
      font-family: "Inter";
      font-size: 24px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
    }
    i {
      opacity: 0.7;
    }
    .gold {
      color: var(--gold-color);
    }
  }
  @media (max-width: 630px) {
    .content {
      width: clamp(250px, 100%, 400px);
      flex-direction: column;
    }
    .form,
    .info {
      width: 100%;
    }
  }
}

.image {
  background-color: var(--dark-primary-color);
  position: absolute;
  width: clamp(100px, 30%, 200px);
  aspect-ratio: 1/1;
  top: 0;
  left: 50%;
  translate: -50% -50%;
  border-radius: 100%;
  overflow: hidden;
  aspect-ratio: 1/1;
  outline: 4px solid var(--dark-primary-color);
  display: block;
  margin: 0 auto 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  input {
    display: none;
  }
  div {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    translate: 0 100%;
    transition: 0.1s;
    opacity: 0.7;
  }
  &:hover div {
    translate: 0;
  }
}
 