.content {
  display: flex;
  width: 80%;
  margin: 0 auto;
  gap: 5%;
  height: 100%;
  @media (max-width: 1200px) {
    width: 90%;
  }
}
.content-wrapper {
  height: calc(100vh - 50px);
}

.sidebar {
  width: 35%;
  height: 100%;
  overflow-y: auto;
  color: var(--light-font-color);
  background-color: var(--light-primary-color);
  padding-bottom: 40vh;
  .progress {
    background-color: var(--progress-background-color);
    padding: 15px;

    .progress-stick {
      height: 10px;
      background-color: var(--progress-color);
      margin: 6px 0;
      position: relative;
      border-radius: 10px;
    }
    .progress-stick::after {
      content: "";
      border-radius: 10px;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      background-color: var(--gold-color);
      width: var(--progress);
    }
    h3 {
      text-align: end;
      color: var(--gold-color);
      font-size: 20px;
    }
  }
  .video {
    align-items: center;
    display: flex;
    gap: 10px;
    padding: 15px 5%;
    transition: 0.2s;
    color: var(--light-font-color);
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.active {
      position: relative;
      color: var(--white-color);
      background-color: var(--menu-active-color);
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 3px;
        background-color: var(--gold-color);
      }
    }

    h1 {
      margin-bottom: 10px;
      font-size: clamp(8px, 1.5vw, 18px);
      font-weight: 500;
    }
    p {
      font-size: 14px;
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      font-size: 20px;
      aspect-ratio: 1/1;
      min-width: 50px;
      background-color: var(--dark-primary-color);
      font-weight: 600;
      color: var(--light-font-color);
    }
    .number img {
      width: 50%;
    }
  }
}

.video-content {
  width: 65%;
  height: 100%;
  overflow-y: auto;
  padding: 10vh 20px 10vh 0;
  .iframe {
    width: 100%;
    aspect-ratio: 48 / 27;
  }
  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    gap: 20px;
    color: var(--light-font-color);
  }
  .description {
    line-height: 150%;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.01);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 930px) {
  .content {
    flex-direction: column-reverse;
  }
  .content-wrapper {
    overflow-y: auto;
     height: unset;
  }
  .sidebar,
  .video-content {
    padding : 0;
    overflow: unset;
    width: 100%;
  }
  .video-content {
    padding: 40px  0  ;

    .title {
      font-size: 13px;
      margin: 20px 0;
    }
  }
}
@media (max-width: 500px) {
  .video-content .title {
    font-size: clamp(8px, 2vw, 13px);
    margin: 10px 0;
  }
  .sidebar .video {
    h1 {
      font-size: clamp(8px, 5vw, 18px);
      margin-bottom: 2vw;
    }
    p {
      font-size: clamp(6px, 4vw, 16px);
    }
  }
}
