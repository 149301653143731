.border-container {
  border-radius: 22px;
  position: relative;
  background-color: var(--light-primary-color);
  // z-index: 1;

  &::after {
    position: absolute;
    inset: -2px;
    content: "";
    z-index: -1;
    border-radius: 23px;
    transition: 1s;
    background: linear-gradient(135deg, var(--color1), var(--color2) 60%);
  }
  @media (max-width: 600px) {
    border-radius: 15px;
    &::after{

      border-radius: 15px;
    }
  }
}
