.content {
  height: 100vh;
  width: 100%;
}

.spin{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 10vh;
  gap: 20px;
  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card {
  background-color: var(--light-primary-color);
  border-radius: 7px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .top {
    height: 100%;
    padding-bottom: 20px;
    display: flex;
    gap: 5%;
    img {
      width: 50%;
      aspect-ratio: 1/1;
      max-height: 300px;
      object-fit: contain;
    }
    .text {
      width: 50%;
    }
  }
  h1 {
    font-size: 16px;
    color: var(--gold-color);
    font-family: "Montserrat";
    margin-bottom: 10px;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    font-family: "Inter";
    color: var(--light-font-color);
  }
  @media (max-width: 1000px) {
    padding: 15px;
    .top {
      flex-direction: column;
      gap: 20px;
      img,
      .text {
        width: 100%;
      }
    }
  }
}
