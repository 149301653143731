.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: clamp(200px, 90vw, 360px);
    padding: 50px clamp(15px, 5vw, 30px);
     color: var(--white-color);
  }
  .title{
    font-size: 24px;    text-align: center;

  }

  .description{
    font-size: 12px;
    margin: 12px 0 20px;    text-align: center;

  }
}
