.container {
  color: var(--white-color);
  padding: 10vh 5vw;
  height: 100%;
  overflow-y: scroll;
  .title {
    text-align: center;
    font-weight: 400;
    padding-bottom: 5vh;
    font-size: clamp(20px, 10vw, 48px);
  }
}
