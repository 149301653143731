* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  //  outline: 2px solid rgba(255, 142, 142, 0.5);
}

:root {
  --primary-font-color: #92a4bb;
  --light-font-color: #e4e4e4;
  --input-label-color: #939496;
  --dark-primary-color: #202225;
  --light-primary-color: #2f3136;
  --gold-color: #ebc870;
  --dark-gold-color: #b0934a;
  --gold-color-0: #ebc87000;
  --menu-active-color: #4b483f;
  --progress-background-color: #272c34;
  --progress-color: #3d3f44;
  --white-color: #ffffff;
  --black-color: #000000;
}
label {
  color: var(--input-label-color) !important;
}

input,
.password-input {
  background-color: var(--dark-primary-color) !important;
  color: var(--white-color) !important;
  border-color: #665b3e94 !important;
  &::placeholder {
    color: var(--primary-font-color) !important;
  }
  &[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
.password-input svg {
  color: var(--primary-font-color);
}

.ant-btn-primary {
  background: linear-gradient(
    180deg,
    var(--gold-color),
    var(--dark-gold-color)
  );
  font-weight: 500;
  letter-spacing: 0.5px;
  &:hover {
    background: linear-gradient(
      180deg,
      var(--gold-color) 20%,
      var(--dark-gold-color) 110%
    ) !important;
  }
  span {
    color: var(--light-primary-color);
  }
}
:where(.css-dev-only-do-not-override-1ezjvgn).ant-upload-wrapper .ant-upload-drag .ant-upload-btn{
  background-color: var(--dark-primary-color);
  padding: 10%;
}

:where(.css-dev-only-do-not-override-1ezjvgn).ant-upload-wrapper .ant-upload-list *{
  color: var(--white-color) !important;
}

a {
  text-decoration: none;
  color: var(--gold-color);
  &#link {
    color: var(--white-color);
    &:hover,
    &.active {
      color: var(--gold-color);
    }
  }

  &:hover {
    color: #ebc870;
  }
}
body {
  background-color: var(--dark-primary-color);
  color: var(--primary-font-color);
  font-family: "Montserrat";
}

.light-bg {
  background-color: var(--light-primary-color);
}


.input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 6px;
  transition: all 0.2s;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  text-overflow: ellipsis;
  &:focus {
    box-shadow: 0 0 0 2px rgba(255, 222, 5, 0.06);
    outline: 0;
  }
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  padding-left: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  width: 4px;
  margin: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6);
}
