.container {
  height: calc(100vh - 50px);
  overflow-y: scroll;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10vh 5vw;
  gap: 20px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    .card {
      padding: 15px;
    }
  }
}

.card {
  background-color: var(--light-primary-color);
  border-radius: 7px;
  padding: 20px;
  display: flex;
  gap: 5%;
  row-gap: 30px;
  img {
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 5px;
    object-fit: contain;
  }
  .text {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  h1 {
    font-size: 16px;
    color: var(--gold-color);
    font-family: "Montserrat";
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    font-family: "Inter";
    color: var(--light-font-color);
  }
  .button {
    display: block;
    margin-top: auto;
    width: 100%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    img {
      width: 100%;
      height: clamp(200px, 25vw, 200px);
    }
    .text {
      width: 100%;
      height: clamp(50px, 100%, 200px);
    }
  }
}
