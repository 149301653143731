.container {
  width: 100px;
  background-color: var(--light-primary-color);
  .menuLink:has(.profile) {
    padding: 15px;
    border-bottom: none;
  }
  .profile {
    border-radius: 100px;
  }
  .line {
    height: 5px;
    background: radial-gradient(white, transparent, transparent);
  }

  .menuLink {
    display: block;
    width: 100%;
    padding: 20px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(203, 197, 83, 0.1);
    &:hover,
    &.active {
      background-color: #242526;
    }
    svg {
      font-size: 50px;
    }
    img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      // height: 100%;
    }
    .courseImage{
      object-fit: contain;

    }
  }
  @media (max-width: 450px) {
    width: 70px;
    .menuLink {
      padding: 13px;
    }
    .menuLink svg {
      font-size: 20px;
    }
  }
}
