.container {
  margin: 0 auto;
  width: clamp(200px, 86vw, 500px);
  padding: clamp(20px, 10vw, 60px) clamp(10px, 5vw,35px);
  text-align: center;
  .white {
      color: var(--white-color) !important;
  }
  .gold {
    color: var(--gold-color) !important;
  }

  .title {
    color: var(--gold-color);
    font-weight: 600;
    font-size: clamp(15px, 5vw, 34px);
    cursor: pointer;
  }
  .description {
    margin: 20px 0;
    font-size: clamp(10px, 2vw, 14px);
    line-height: 140%;
  }
 .button {
    display: block;
    margin-left: auto;
    margin-top: 30px;
    width: 50%;
    min-width: 100px;
  }
  .dragger {
    background-color: var(--dark-primary-color);
  }
}
